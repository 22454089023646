"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const mdb_react_ui_kit_1 = require("mdb-react-ui-kit");
const tech_icons_1 = require("../tech-icons");
function Skills(props) {
    const { id } = props;
    // eslint-disable-next-line prettier/prettier, @typescript-eslint/no-unused-vars
    const mobileCol1 = ["Cypress", "Testing Library", "Javascrpt", "Typescrpt", "React", "Git", "VIM", "Gitlab", "Bitbucket", "Github"];
    // eslint-disable-next-line prettier/prettier, @typescript-eslint/no-unused-vars
    const mobileCol2 = ["Webpack", "qtest", "Browsertack", "VSCode", "YAML", "Ember", "HTML", "CSS", "Styled Components"];
    return ((0, jsx_runtime_1.jsxs)("div", Object.assign({ id: id, "data-testid": id, className: "pt-5" }, { children: [(0, jsx_runtime_1.jsx)(mdb_react_ui_kit_1.MDBTypography, Object.assign({ tag: "h2", className: "text-center py-2" }, { children: "Skills" })), (0, jsx_runtime_1.jsxs)("div", Object.assign({ className: "skills-list d-flex flex-wrap flex-xl-nowrap justify-content-evenly justify-content-sm-center align-items-center" }, { children: [tech_icons_1.testingTools.map((tool) => {
                        return (0, tech_icons_1.getImage)(tool);
                    }), tech_icons_1.webDevTools.map((tool) => {
                        return (0, tech_icons_1.getImage)(tool);
                    }), tech_icons_1.terminalTools.map((tool) => {
                        return (0, tech_icons_1.getImage)(tool);
                    }), tech_icons_1.cloudHosting.map((cloud) => {
                        return (0, tech_icons_1.getImage)(cloud);
                    })] }))] })));
}
exports.default = Skills;
