"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const mdb_react_ui_kit_1 = require("mdb-react-ui-kit");
// eslint-disable-next-line react/function-component-definition
const CardContainer = (props) => {
    const { title, subtitle, text, url, urlText, url2, url2Text } = props;
    const multipleUrls = ((0, jsx_runtime_1.jsx)(mdb_react_ui_kit_1.MDBBtn, Object.assign({ href: url2, target: "_blank", className: "ms-3" }, { children: url2Text })));
    return ((0, jsx_runtime_1.jsx)(mdb_react_ui_kit_1.MDBCard, { children: (0, jsx_runtime_1.jsxs)(mdb_react_ui_kit_1.MDBCardBody, Object.assign({ "data-testid": `card-${title}` }, { children: [(0, jsx_runtime_1.jsx)(mdb_react_ui_kit_1.MDBCardTitle, Object.assign({ tag: "h4", className: "fs-5" }, { children: title })), (0, jsx_runtime_1.jsx)(mdb_react_ui_kit_1.MDBCardSubTitle, { children: subtitle }), (0, jsx_runtime_1.jsx)(mdb_react_ui_kit_1.MDBCardText, { children: text }), (0, jsx_runtime_1.jsx)(mdb_react_ui_kit_1.MDBBtn, Object.assign({ href: url, target: "_blank" }, { children: urlText })), url2 && multipleUrls] })) }));
};
exports.default = CardContainer;
