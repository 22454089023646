"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const mdb_react_ui_kit_1 = require("mdb-react-ui-kit");
// eslint-disable-next-line react/function-component-definition
const Footer = () => {
    const currentYear = new Date().getFullYear();
    return ((0, jsx_runtime_1.jsxs)(mdb_react_ui_kit_1.MDBFooter, Object.assign({ className: "d-flex justify-content-between align-items-center" }, { children: [(0, jsx_runtime_1.jsx)("div", {}), (0, jsx_runtime_1.jsxs)("div", Object.assign({ className: "copyright" }, { children: ["\u00A9 ", currentYear, " Copyright Kim Bell"] })), (0, jsx_runtime_1.jsxs)("div", Object.assign({ className: "footer-links" }, { children: [(0, jsx_runtime_1.jsx)("a", Object.assign({ href: "https://github.com/TzolkinB", "aria-label": "link to github", target: "_blank", rel: "noreferrer", className: "px-2 pt-4 pb-2" }, { children: (0, jsx_runtime_1.jsx)("i", { className: "devicon-github-original", style: { fontSize: 40 } }) })), (0, jsx_runtime_1.jsx)("a", Object.assign({ href: "https://www.linkedin.com/in/kimbell4", "aria-label": "link to linkedin", target: "_blank", rel: "noreferrer", className: "px-2 pt-4 pb-2" }, { children: (0, jsx_runtime_1.jsx)("i", { className: "devicon-linkedin-plain", style: { fontSize: 40 } }) }))] }))] })));
};
exports.default = Footer;
